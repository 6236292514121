import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Utility from '../../../scripts/utility';

const Dropdown = ({
	data,
	value,
	placeholder,
	multiple,
	identifier,
	onChange,
	error,
	freeSolo,
	filterSelectedOptions,
	neverOpen,
	...props
}) => {
	const id = identifier || 'id';

	const [open, setOpen] = React.useState(false);

	const handleDelete = chipToDelete => {
		const newValue = value.filter(v => v !== chipToDelete);
		const event = { target: { name: props.name, value: newValue } };
		if (typeof onChange === 'function') onChange(event);
	};

	return (
		<div>
			{multiple && value.length > 0 && (
				<div style={{ marginBottom: '10px' }}>
					{value.map(selectedValue => (
						<Chip
							key={selectedValue[id]}
							label={Utility.getItemLabel(data, selectedValue, id)}
							onDelete={() => handleDelete(selectedValue)}
							style={{ marginRight: '5px', marginBottom: '5px' }}
						/>
					))}
				</div>
			)}
			<Autocomplete
				open={neverOpen ? false : open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={e => {
					if (e.target.value === 0 && multiple) {
						// When the dropdown is closing because the user selected an item, e.target.value is the number 0.
					} else {
						setOpen(false);
					}
				}}
				disableClearable
				filterSelectedOptions={filterSelectedOptions !== undefined ? filterSelectedOptions : true}
				filterOptions={(options, { inputValue }) => {
					return options
						.filter(i => {
							if (i.label) {
								return i.label.toLowerCase().includes(inputValue.toLowerCase());
							}
							return i.toLowerCase().includes(inputValue.toLowerCase());
						})
						.filter((option, i) => i < 250);
				}}
				className={error ? 'error' : ''}
				freeSolo={freeSolo}
				multiple={false}
				options={data || []}
				value={null}
				getOptionLabel={option =>
					String(
						Utility.getItemLabel(data, option, id) ||
							Utility.getFromObj(Utility.getIDFromObj(data, option, id), 'skill', 'label') ||
							Utility.getItemName(data, option) ||
							option.label ||
							option.name ||
							Utility.getFromObj(option, 'skill', 'label') ||
							option,
					)
				}
				getOptionSelected={(option, val) => option[id] === val}
				renderInput={params => {
					return (
						<TextField
							{...params}
							variant="outlined"
							placeholder={placeholder}
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password',
							}}
						/>
					);
				}}
				{...props}
				onChange={(ev, val) => {
					const event = { ...ev, target: {} };

					if (val === null) {
						event.target.value = null;
					} else if (multiple) {
						const currentValues = Array.isArray(value) ? value : [];
						const newValues = Array.isArray(val) ? val : [val];
						const mergedValues = [...currentValues, ...newValues.filter(v => !currentValues.includes(v))];

						event.target.value = mergedValues.map(v => (typeof v === 'object' ? v[id] : v));
					} else {
						event.target.value = typeof val === 'object' ? val[id] : val;
					}

					event.target.name = props.name;

					if (typeof onChange === 'function') onChange(event);
				}}
			/>
		</div>
	);
};

Dropdown.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	value: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
		PropTypes.number,
		PropTypes.string,
	]),
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	multiple: PropTypes.bool,
	identifier: PropTypes.string,
	onChange: PropTypes.func,
	error: PropTypes.bool,
	freeSolo: PropTypes.bool,
	autoComplete: PropTypes.string,
};

Dropdown.defaultProps = {
	value: null,
	placeholder: null,
	multiple: false,
	identifier: 'id',
	onChange: () => {},
	error: false,
	freeSolo: false,
	autoComplete: null,
};

export default Dropdown;
