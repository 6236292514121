import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Chip, IconButton, Tooltip, Grid } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import moment from 'moment';

import LabeledField from '../form/LabeledField';
import Page from '../page/Page';
import * as PageHeader from '../page/PageHeader';
import Table from '../Table';
import JobDeleteModal from '../modals/JobDeleteModal';
import JobFilters from '../filters/JobFilters';
import { InterestIcon } from '../modals/RateJobClientInterestModal';
import { SkillChip } from '../../pages/candidates/CandidateBlocks/ViewSkillsBlock';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import { palette } from '../../BobcatTheme';
import * as CandidatesAPI from '../../scripts/candidates';
import CheckboxInput from '../form/inputs/CheckboxInput';
import SharePermissionsModal from '../modals/SharePermissionsModal';
import JobLogoLinks from './JobLogoLinks';

const JobMatchCardItem = ({
	match,
	job: propJob,
	selected,
	graphMatch,
	small,
	toggleSelected,
	setMenu,
	hideItemDetails,
}) => {
	const job = match?.listing || propJob;

	const { data, user } = useSelector(state => state.site, shallowEqual);

	const isShareable = job?.is_sharable;

	// const viewed = Math.random() > 0.5;

	const bid = job?.vendor_job_id;
	const myFitRanking = graphMatch?.fit_rankings?.find(x => x?.user?.id === user?.id)?.match_fit_ranking?.id;

	const graphMatchViewers = {};
	graphMatch?.views?.forEach(x => {
		graphMatchViewers[x.viewed_by] = x;
	});
	const numGraphMatchViewers = Object.keys(graphMatchViewers).length;

	const graphMatchFitRatings = {};
	graphMatch?.fit_rankings?.forEach(x => {
		graphMatchFitRatings[x.created_by] = x;
	});
	const numGraphMatchFitRatings = Object.keys(graphMatchFitRatings).length;

	if (!job) {
		return null;
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 20,
				// background: viewed ? '#f5f5f5' : 'transparent',
			}}
		>
			<CheckboxInput
				style={{ marginRight: -15 }}
				color="primary"
				checked={selected}
				// onChange={() => {}}
				onClick={() => {
					toggleSelected();
				}}
			/>
			<div
				style={{
					flex: 1,
					// opacity: viewed ? 0.7 : 1,
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{/* <Typography
							variant="body1"
							style={{ fontSize: 13, fontWeight: 500, marginBottom: 0, color: `var(--teal-100)` }}
						>
							95% Match
						</Typography>
						<Typography
							variant="body1"
							style={{ fontSize: 13, marginRight: 10, marginLeft: 10, color: `var(--grey-100)` }}
						>
							{' | '}
						</Typography> */}
						{graphMatch?.overall_match_score !== undefined && !hideItemDetails && (
							<>
								<Typography
									variant="body1"
									style={{ fontSize: 13, fontWeight: 500, marginBottom: 0, color: `var(--teal-100)` }}
								>
									{Math.round(graphMatch?.overall_match_score * 100 || 0) || '0'}%
								</Typography>
								<Typography
									variant="body1"
									style={{ fontSize: 13, marginRight: 3, marginLeft: 3, color: `var(--grey-300)` }}
								>
									({Math.round(graphMatch?.completeness_score_overall * 100 || 0) || '0'}%)
								</Typography>
								{/* {viewed &&
									 <Icons.Visibility style={{ width: 16, opacity: 0.5, marginLeft: 5, marginRight: 5 }} />} */}
								<Typography
									variant="body1"
									style={{ fontSize: 13, marginRight: 3, marginLeft: 3, color: `var(--grey-300)` }}
								>
									{' | '}
								</Typography>
							</>
						)}

						{!hideItemDetails && (
							<Typography
								variant="body1"
								style={{ fontSize: 13, fontWeight: 300, marginBottom: 0, color: 'var(--grey-300)' }}
							>
								{data?.jobStatuses?.find(x => x.id === job?.status_id)?.label} |{' '}
								<Chip
									size="small"
									label={job?.closed ? 'Closed' : 'Open'}
									className={`color-${job?.closed ? 'error' : 'ready'}`}
									style={{ margin: 0, marginLeft: '.08rem' }}
								/>
							</Typography>
						)}
						{!hideItemDetails && (
							<>
								<JobLogoLinks job={job} />
							</>
						)}
					</div>
					<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 0 }}>
						{match?.interest_level && !hideItemDetails && (
							<Tooltip title={`Rated: ${match?.interest_level?.label}`}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '5px 8px 5px 8px',
										backgroundColor: '#EEF6FF',
										fontStyle: 'italic',
										marginRight: 5,
										userSelect: 'none',
										height: 31,
									}}
								>
									<InterestIcon interestLevelId={match?.interest_level?.id} color="#465670" size={16} />

									{/* <Icons.ThumbUpOutlined style={{ width: 16 }} />
									<div style={{ width: 5 }} />
									{match?.interest_level?.label} */}
								</div>
							</Tooltip>
						)}

						{match?.user_viewed_at && !hideItemDetails && (
							<Tooltip title={`Viewed ${moment(match?.user_viewed_at).fromNow()}`}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '5px 8px 5px 8px',
										backgroundColor: '#EEF6FF',
										fontStyle: 'italic',
										marginRight: 5,
										userSelect: 'none',
									}}
								>
									<Icons.Visibility style={{ width: 16 }} />
									{/* <div style={{ width: 5 }} />
									Viewed */}
								</div>
							</Tooltip>
						)}
						{match?.is_published && !hideItemDetails && (
							<Tooltip title={match?.access_level?.id === 3 ? 'Shared with full access' : 'Shared with limited access'}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: 5,
										backgroundColor: '#E9FCFB',
										// fontStyle: 'italic',
										fontSize: 11,
										marginRight: 5,
										userSelect: 'none',
									}}
								>
									<Icons.Share style={{ width: 16 }} />
									<div style={{ width: 5 }} />
									{match?.access_level?.id === 3 ? 'Shared' : 'Limited'}
								</div>
							</Tooltip>
						)}
						{match && !hideItemDetails && (
							<Tooltip title="Saved">
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: 5,
										backgroundColor: '#F3F6F9',
										fontStyle: 'italic',
										marginRight: 0,
										userSelect: 'none',
									}}
								>
									<Icons.BookmarkBorder style={{ width: 16 }} />
									{/* <div style={{ width: 5 }} />
									Saved */}
								</div>
							</Tooltip>
						)}
						<div style={{ width: 15 }} />
						<IconButton onClick={ev => setMenu({ anchor: ev.target, listing: job, match })}>
							<Icons.MoreVert color="primary" />
						</IconButton>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<Typography
						variant="body2"
						style={{ maxWidth: '75%', marginTop: 0, marginBottom: 2, fontSize: 15, color: 'var(--grey-400)' }}
					>
						<NavLink to={`/job/${job.id}`} className="color-inherit" target="_blank">
							{job.position_title} at {job.company}
							{/* | {job.vendor_job_id} */}
						</NavLink>
					</Typography>
					{!small && !hideItemDetails ? (
						<Typography
							variant="body1"
							style={{ marginBottom: 0, marginLeft: 5, fontSize: 13, color: 'var(--grey-300)' }}
						>
							{` | Exp: `}
							{job.professional_experience_years || 0} yr{job.professional_experience_years === 1 ? '' : 's'}
							{job?.total_leadership_years
								? ` | Leadership: ${job?.total_leadership_years || 0} yr${job?.total_leadership_years === 1 ? '' : 's'}`
								: ''}
							{/* | Job Created:{' '}
					{Utility.formatDate(job.created_at) || Utility.na} | Contact:{' '}
					{job?.contacts?.[0] ? `${job?.contacts?.[0]?.first_name} ${job?.contacts?.[0]?.last_name}` : 'N/A'} */}
						</Typography>
					) : null}
					{!small && !hideItemDetails && (
						<Typography
							variant="body1"
							style={{ marginBottom: 0, marginLeft: 20, fontSize: 13, color: 'var(--grey-300)' }}
						>
							Created: {Utility.formatDate(job.created_at)} | Updated: {Utility.formatDate(job.updated_at)}
						</Typography>
					)}
				</div>

				{!hideItemDetails && (
					<Grid item xs={12} style={{ marginBottom: -12, paddingTop: 5 }}>
						{(job.skills || [])
							.filter(x => x.hero_skill)
							.map(skill => (
								<SkillChip
									baseColor="blue"
									key={skill?.id}
									skill={{
										...skill,
										// required_skill: true,
									}}
								/>
							))}
					</Grid>
				)}

				{!hideItemDetails && (
					<Typography variant="body1" style={{ marginTop: 5, marginBottom: 0, fontSize: 13, color: 'var(--grey-300)' }}>
						Salary: {Utility.formatMoney(job.salary_rate_min, { hideDecimal: true })} -{' '}
						{Utility.formatMoney(job.salary_rate_max, { hideDecimal: true })} |{' '}
						{job?.office_locations?.length
							? job.office_locations.slice(0, 2).map(item => <>{`${item?.city}, ${item?.state?.name} | `}</>)
							: Utility.nb}
						{Utility.getConsolidatedLocationPreferences(job?.work_location_preferences).join(' | ')}
						{/* | Job Created:{' '}
					{Utility.formatDate(job.created_at) || Utility.na} | Contact:{' '}
					{job?.contacts?.[0] ? `${job?.contacts?.[0]?.first_name} ${job?.contacts?.[0]?.last_name}` : 'N/A'} */}
					</Typography>
				)}

				<Grid item xs={12} style={{ marginBottom: -12 }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{graphMatch ? (
							<>
								<Tooltip
									title={
										<div>
											{numGraphMatchViewers > 0
												? Object.values(graphMatchViewers)?.map(x => (
														<p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
															{x?.viewed_by_user?.first_name} {x?.viewed_by_user?.last_name}
															<span style={{ marginLeft: 3, fontSize: 11, fontStyle: 'italic', opacity: 0.7 }}>
																{moment(x?.viewed_at).fromNow()}
															</span>
														</p>
												  ))
												: 'None'}
										</div>
									}
								>
									<div style={{ display: 'flex', alignItems: 'center', cursor: 'default', marginTop: 3 }}>
										<Icons.Visibility style={{ width: 12, opacity: 0.3, marginRight: 5 }} />
										<Typography
											variant="body1"
											style={{ fontSize: 13, color: 'var(--grey-300)' }}
										>{`${numGraphMatchViewers} view${numGraphMatchViewers !== 1 ? 's' : ''}`}</Typography>
									</div>
								</Tooltip>
								<div style={{ width: 15 }} />
								<Tooltip
									title={
										<div>
											{numGraphMatchFitRatings > 0
												? Object.values(graphMatchFitRatings)?.map(x => (
														<p style={{ fontSize: 12, marginTop: 3, marginBottom: 3 }}>
															{x?.user?.first_name} {x?.user?.last_name}: {x?.match_fit_ranking?.label}
															<span style={{ marginLeft: 3, fontSize: 11, fontStyle: 'italic', opacity: 0.7 }}>
																{moment(x?.created_at).fromNow()}
															</span>{' '}
														</p>
												  ))
												: 'None'}
										</div>
									}
								>
									<div style={{ display: 'flex', alignItems: 'center', cursor: 'default', marginTop: 3 }}>
										<Icons.ThumbUp style={{ width: 12, opacity: 0.3, marginRight: 5 }} />
										<Typography
											variant="body1"
											style={{ fontSize: 13, color: 'var(--grey-300)' }}
										>{`${numGraphMatchFitRatings} ranking${numGraphMatchFitRatings !== 1 ? 's' : ''}`}</Typography>
									</div>
								</Tooltip>
							</>
						) : null}
					</div>
				</Grid>

				{/* <Typography variant="body1" style={{ fontSize: 13, color: 'var(--grey-300)' }}>
					Hero Skills:{' '}
					{job?.skills
						?.filter(s => s?.hero_skill)
						?.slice(0, 3)
						?.map(x => x?.skill?.label || '-')
						?.join(', ')}
				</Typography> */}
				{/* <Typography variant="body1" style={{ marginBottom: 0, fontSize: 13, color: 'var(--grey-300)' }}>
					Portland, OR | Salary: $170,000 | Job Created: 10/1/22 | Contact: Miles Mattern
				</Typography>
				<Typography variant="body1" style={{ fontSize: 13, color: 'var(--grey-300)' }}>
					Hero Skills: Java, DevOps, AWS
				</Typography> */}
			</div>
		</div>
	);
};

export default JobMatchCardItem;
