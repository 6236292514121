import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography, ButtonBase, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import Fence from '../../../components/Fence';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import EditCandidateBlockModal from './EditCandidateBlockModal';
import SkillsIcon from '../../../components/icons/SkillsIcon';
import LeadershipAllocationDropdown from '../../../components/form/dropdowns/LeadershipAllocationDropdown';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import SkillDropdown from '../../../components/form/dropdowns/SkillDropdown';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';
import VerticalSpacer from '../../../components/VerticalSpacer';

const EditSkillsModal = ({ candidate, setCandidate }) => {
	const [minimizeUncategorized, setMinimizeUncategorized] = useState(true);

	return (
		<EditCandidateBlockModal
			candidate={candidate}
			setCandidate={setCandidate}
			title="Skills"
			icon={SkillsIcon}
			overrideSaveCandidate={({ setIsSaving, store, updatedCandidate, dispatch }) => {
				setIsSaving(true);
				CandidatesAPI.syncCandidateSkills(candidate.id, updatedCandidate.skills, response => {
					if (!response) {
						setIsSaving(false);
						store.dispatch(
							SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'),
						);
						return;
					}
					// console.log({ newSkillsPre: response.data.data?.map(x => x?.skill?.name) });
					console.log('Setting new candidate...');
					console.log({
						setCandidate,
						updatedCandidate,
						newSkills: response.data.data.map(x => x.skill.name),
					});
					setCandidate({
						...updatedCandidate,
						skills: response.data.data,
					});
					dispatch(SiteActions.hideModal());
				});
			}}
			renderContents={({ updateCandidate, updatedCandidate, setUpdatedCandidate }) => {
				const [validSkills, setValidSkills] = useState([...updatedCandidate.skills]);

				const { data } = useSelector(state => state.site, shallowEqual);
				const [showHelp, setShowHelp] = useState(false);

				const updateSkills = (levelId, skillIds) => {
					const removedSkillIds = updatedCandidate.skills
						.filter(s => s.skill_experience_id === levelId)
						.filter(s => !skillIds.includes(s.skill_id))
						.map(s => s.skill_id);

					const newSkills = skillIds
						.filter(skillId => !updatedCandidate.skills.find(s => s.skill_id === skillId))
						.map(skillId => ({
							skill_id: skillId,
							skill: validSkills.find(vs => vs.id === skillId),
							skill_experience_id: levelId,
							experience_years: 0,
						}));

					const updatedSkills = updatedCandidate.skills
						// .filter(s => !removedSkillIds.includes(s.skill_id)) // remove skills
						.map(s =>
							removedSkillIds.includes(s.skill_id)
								? { ...s, skill_experience_id: null, experience: null }
								: skillIds.includes(s.skill_id)
								? { ...s, skill_experience_id: levelId }
								: s,
						) // update xp id
						.concat(newSkills);

					setUpdatedCandidate(c => ({ ...c, skills: updatedSkills }));
				};

				const validGetTheJobSkills = updatedCandidate.skills
					// .filter(
					// 	s => [4, 5].includes(s.skill_experience_id), // expert or advanced skills
					// )
					.map(s => ({ ...s, id: s.skill?.id || s.skill_id }));

				const validImproveOnJobSkills = updatedCandidate?.skills?.filter(
					x =>
						(x.skill_experience_id || x.experience) &&
						(x.improve_on_job || (!x.get_the_job && !x.enjoy_the_job && !x.learn_on_job)),
				);

				const validEnjoyOnJobSkills = updatedCandidate?.skills?.filter(
					x => (x.skill_experience_id || x.experience) && (x.enjoy_the_job || (!x.improve_on_job && !x.learn_on_job)),
				);

				const getTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.get_the_job), [
					updatedCandidate.skills,
				]);
				const enjoyTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.enjoy_the_job), [
					updatedCandidate.skills,
				]);
				const improveOnTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.improve_on_job), [
					updatedCandidate.skills,
				]);
				const learnOnTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.learn_on_job), [
					updatedCandidate.skills,
				]);

				const unCategorizedSkills = useMemo(
					() =>
						updatedCandidate?.skills?.filter(
							skill =>
								(!skill.skill_experience_id || skill.skill_experience_id === 1) &&
								!skill.get_the_job &&
								!skill.enjoy_the_job &&
								!skill.improve_on_job &&
								!skill.learn_on_job,
						),
					[updatedCandidate.skills],
				);

				const selectGetJobSkill = (skillId, replacedSkillId) => {
					const updatedSkills = updatedCandidate.skills.map(s => {
						if (s.skill_id === replacedSkillId) return { ...s, get_the_job: false };
						if (s.skill_id === skillId) return { ...s, get_the_job: true };
						return s;
					});
					setUpdatedCandidate(s => ({ ...s, skills: updatedSkills }));
				};

				const setSkillExperience = (skill, experienceYears) => {
					const updatedSkills = updatedCandidate.skills.map(s =>
						s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
					);
					setUpdatedCandidate(c => ({
						...c,
						skills: updatedSkills,
					}));
				};

				const handleUncategorizedChange = (skillStoryName, skillIds) => {
					const removedSkillIds = unCategorizedSkills.filter(s => !skillIds.includes(s.skill_id)).map(s => s.skill_id);

					const updatedSkills = updatedCandidate.skills.filter(s => !removedSkillIds.includes(s.skill_id));

					setUpdatedCandidate(c => ({ ...c, skills: updatedSkills }));
				};

				const selectSkill = (skillStoryName, skillIds) => {
					// create an array of skills that are new; not already in updatedCandidate.skills
					const newSkills = skillIds
						.filter(sid => !updatedCandidate.skills.find(s => s.skill_id === sid))
						.map(sid => {
							return {
								skill_id: sid,
								[skillStoryName]: true,
								skill_experience_id: 1,
								experience_years: 0,
								skill: validSkills.find(s => s.id === sid),
							};
						});

					if (
						(skillStoryName === 'get_the_job' && skillIds.length > 3) ||
						(skillStoryName === 'enjoy_the_job' && skillIds.length > 5) ||
						(skillStoryName === 'improve_on_job' && skillIds.length > 5) ||
						(skillStoryName === 'learn_on_job' && skillIds.length > 5)
					) {
						return null;
					}

					// for each input skill (skillIds) set the candidate.skill story name to true
					const updatedSkills = updatedCandidate.skills
						.map(s => ({ ...s, [skillStoryName]: skillIds.includes(s.skill_id) }))
						.concat(newSkills);
					setUpdatedCandidate(c => ({ ...c, skills: updatedSkills }));
					return null;
				};

				// useEffect(() => {
				// 	// if (!validSkills.length) {
				// 	CandidatesAPI.getValidSkills(candidate.id, response => {
				// 		if (response) setValidSkills(response.data.data);
				// 	});
				// 	// }
				// }, [candidate.id]);

				console.log({ unCategorizedSkills });

				return (
					<>
						{/* <Grid container spacing={2}>
							<Grid item xs={5}>
								<LabeledField label="Category" required>
									<CategoryDropdown
										variant="outlined"
										name="professional_category_id"
										value={updatedCandidate.professional_category_id}
										onChange={updateCandidate}
										error={!updatedCandidate.professional_category_id}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={2} />
							<Grid item xs={5}>
								<LabeledField label="Specialty">
									<SpecialtyDropdown
										variant="outlined"
										name="specialty_id"
										value={updatedCandidate.specialty_id}
										placeholder="Choose from our list"
										category={updatedCandidate.professional_category_id}
										onChange={updateCandidate}
										disabled={
											!updatedCandidate.professional_category_id ||
											updatedCandidate.leadership_allocation_type_id === 3 ||
											updatedCandidate.leadership_allocation_type_id === 4
										}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid> */}
						{[...data.skillExperienceLevels]
							.reverse()
							.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
							.map(level => (
								<LabeledField
									key={level.name}
									label={
										{
											Expert: 'Best Skills',
											Advanced: 'Advanced Skills',
											Intermediate: 'Intermediate Skills',
											Novice: 'Basic Skills',
										}[level.label] || level.label
									}
									// required
								>
									<ElasticSkillDropdown
										tags
										variant="outlined"
										color="primary"
										name={`skills_${level.name}`}
										value={updatedCandidate.skills
											.filter(s => s.skill_experience_id === level.id && !s.skill?.needs_review)
											.map(s => s.skill_id)}
										additionalSkills={updatedCandidate.skills
											?.map(s => ({
												id: s.skill_id,
												label: s.skill?.label || 'Unknown',
											}))
											?.sort((a, b) => a.label.localeCompare(b.label))}
										getCategoryLabel={skillId => {
											const skillStory = updatedCandidate.skills.find(
												s => s.skill_id === skillId && (!s.skill_experience_id || s.skill_experience_id === 1),
											);
											if (skillStory) {
												return 'Suggested';
											}
											return 'All';
										}}
										onChange={e => {
											updateSkills(level.id, e.target.value);
										}}
										// error={!updatedCandidate?.skills?.filter(s => s.skill_experience_id === level.id)}
										fullWidth
									/>
								</LabeledField>
							))}
						<br />

						<Typography variant="h3" className="text-left text-medium">
							Skill Story
						</Typography>
						<VerticalSpacer height={1} />

						<Typography className="text-bold">Land the Job</Typography>
						<Typography>
							Select up to three high aptitude skills that are most critical to landing your next role. These should be
							skills that will be the foundation of your daily work.
						</Typography>

						<VerticalSpacer height={1} />
						{[1, 2, 3].map((v, i) => {
							return (
								<Grid container spacing={2} key={`getTheJobSkill-${getTheJobSkills[i]?.skill_id || v}`}>
									<Grid item xs={7}>
										<ElasticSkillDropdown
											variant="outlined"
											name="get_the_job"
											value={getTheJobSkills[i]?.skill_id}
											override={[
												...(validGetTheJobSkills || []),
												{
													id: '',
													label: '-',
												},
											]}
											onChange={ev => selectGetJobSkill(ev.target.value, getTheJobSkills[i]?.skill_id)}
											error={false}
											fullWidth
										/>
									</Grid>
									<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
										<Typography variant="body1" className="text-right text-medium">
											Experience
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<YearsDropdown
											variant="outlined"
											name="get_the_job_years"
											value={getTheJobSkills[i]?.experience_years}
											onChange={ev => setSkillExperience(getTheJobSkills[i], ev.target.value)}
											// disableClearable={false}
											error={getTheJobSkills[i]?.skill_id && !getTheJobSkills[i]?.experience_years}
											fullWidth
										/>
									</Grid>
								</Grid>
							);
						})}
						<VerticalSpacer height={0.5} />
						<ButtonBase onClick={() => setShowHelp(s => !s)} className="button-text-muted">
							Don&lsquo;t See a Skill You&lsquo;re Looking For?
						</ButtonBase>
						{showHelp && (
							<Fence
								style={{
									background: '#E6F1FF',
									borderRadius: 6,
									padding: '46px 30px 28px 30px',
									position: 'relative',
								}}
							>
								<IconButton
									className="modal-close modal-close-primary"
									onClick={() => setShowHelp(false)}
									style={{ marginTop: '-15px', marginRight: '-15px' }}
								>
									<Icons.Close />
								</IconButton>
								<Typography className="text-bold" style={{ marginTop: -18 }}>
									Not seeing a Land the Job Skill?
								</Typography>
								<Typography className="grey-300">
									Skills must be ranked Best or Advanced (in the Skills section) before they can be selected as Land the
									Job skills.
									<br />
									In order to add a new Land the Job skill, you must first rank it accordingly.
								</Typography>
							</Fence>
						)}
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Enjoy Using</Typography>
						<Typography>
							Select skills that you would most like to use regularly on the job. These can overlap with the skills that
							get you the job but they don&rsquo;t have to.
						</Typography>
						<VerticalSpacer height={1} />
						<ElasticSkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="enjoy_the_job"
							value={enjoyTheJobSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validEnjoyOnJobSkills}
							onChange={ev => {
								selectSkill(ev.target.name, ev.target.value);
							}}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Improve</Typography>
						<Typography>
							Select skills you&rsquo;d most like to improve on the job. These are skills you currently have and wish to
							further develop.
						</Typography>
						<VerticalSpacer height={1} />
						<ElasticSkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="improve_on_job"
							value={improveOnTheJobSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validImproveOnJobSkills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Learn</Typography>
						<Typography>
							Add skills you&rsquo;d most like to acquire on the job. These are skills you don&rsquo;t have but want to
							learn.
						</Typography>
						<VerticalSpacer height={1} />
						<ElasticSkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="learn_on_job"
							value={learnOnTheJobSkills.map(s => (s.skill_id ? s.skill_id : s))}
							filter={skillId => {
								// Valid bonus skills are any skill that doesn't already have a skill story for this job.
								const skillOnCandidate = updatedCandidate.skills.find(s => s.skill_id === skillId);
								if (skillOnCandidate) {
									// If the skill has another skill story, it's not a valid bonus skill.
									if (
										skillOnCandidate.get_the_job ||
										skillOnCandidate.enjoy_the_job ||
										skillOnCandidate.improve_on_job
									) {
										return false;
									}
								}

								return true;
							}}
							additionalSkills={updatedCandidate.skills
								?.map(s => ({
									id: s.skill_id,
									label: s.skill?.label || 'Unknown',
								}))
								?.sort((a, b) => a.label.localeCompare(b.label))}
							// override={validLearnSkills}
							getCategoryLabel={skillId => {
								return updatedCandidate.skills.find(s => s.skill_id === skillId) ? 'Suggested' : 'All';
							}}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography
							onClick={() => {
								setMinimizeUncategorized(!minimizeUncategorized);
							}}
							className="text-bold"
							style={{ cursor: 'pointer' }}
						>
							Uncategorized {minimizeUncategorized ? '►' : '▼'}
						</Typography>

						{!minimizeUncategorized && (
							<>
								<Typography>
									Skills attached to the candidate that do not have a skill level or skill story. Removing them deletes
									them from the candidate completely.
								</Typography>

								<VerticalSpacer height={1} />
								<ElasticSkillDropdown
									tags
									variant="outlined"
									color="primary"
									name="uncategorized"
									value={[...unCategorizedSkills]
										?.sort((a, b) => a?.skill?.name.localeCompare(b?.skill?.name))
										.map(s => (s.skill_id ? s.skill_id : s))}
									additionalSkills={updatedCandidate?.skills
										?.map(s => ({
											id: s.skill_id,
											label: s.skill?.label || 'Unknown',
										}))
										?.sort((a, b) => a.label.localeCompare(b.label))}
									onChange={ev => handleUncategorizedChange(ev.target.name, ev.target.value)}
									fullWidth
								/>
							</>
						)}
						<VerticalSpacer height={3} />
					</>
				);
			}}
		/>
	);
};

EditSkillsModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	setCandidate: PropTypes.func,
};

EditSkillsModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditSkillsModal;
